import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiTokenService } from '../services/api-token.service';
import { ExpirationHoursEnum } from '../data/enum/ExpirationHours.enum';
import { PersonalAccessTokenType } from '../data/type/personal-access-token.type';
import { DateTime } from 'luxon';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'mul-api-token-form',
  templateUrl: './api-token-form.component.html'
})
export class ApiTokenFormComponent {
  @Output() saveEventEmitter: EventEmitter<string | null> = new EventEmitter();

  tokenForm: FormGroup = this.formBuilder.group({
    name: '',
    expirationHours: true
  });

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly apiTokenService: ApiTokenService,
    private spinner: NgxSpinnerService
  ) {}

  saveAction() {
    this.spinner.show('token');

    const name =
      <string>this.tokenForm.get('name')?.value ||
      'Personal Access Token ' + DateTime.now().toMillis();
    let expirationHours;

    switch (this.tokenForm.get('expirationHours')?.value) {
      case '1M':
        expirationHours = ExpirationHoursEnum.ONE_MONTH;
        break;
      case '3M':
        expirationHours = ExpirationHoursEnum.THREE_MONTH;
        break;
      case '1Y':
        expirationHours = ExpirationHoursEnum.ONE_YEAR;
        break;
      case 'F':
        expirationHours = ExpirationHoursEnum.FOREVER;
        break;
      default:
        expirationHours = ExpirationHoursEnum.ONE_MONTH;
        break;
    }
    const payload = <PersonalAccessTokenType>{
      name: name,
      expirationHours: expirationHours
    };
    this.apiTokenService.create$(payload).subscribe(res => {
      this.saveEventEmitter.emit(res.token);
    });
  }

  cancelAction() {
    this.saveEventEmitter.emit();
  }
}
