import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mul-code',
  templateUrl: './code.component.html'
})
export class CodeComponent {
  @Input() codeText: string | null = null;
  @Input() console: boolean = true;
  @Output() copyEventEmitter = new EventEmitter<void>();
}
