<div class="flex flex-col bg-primary-100 dark:bg-inverse-100 p-3 rounded-sm">
  <div class="flex justify-center w-[540px] pb-4">
    <mul-code
      [codeText]="token"
      [console]="false"
      (copyEventEmitter)="copyTokenToClipboard()"
    ></mul-code>
  </div>
  <div class="flex justify-center w-[540px]">
    <mul-code
      [codeText]="codeText"
      (copyEventEmitter)="copyCodeToClipboard()"
    ></mul-code>
  </div>
</div>
