import { Injectable } from '@angular/core';
import { RoleEnum } from '../data/enum/role.enum';
import { AuthService } from '../../auth/services/auth.service';
import { AuthModeEnum } from '../data/enum/auth-mode.enum';
import { SubscriptionStatusEnum } from '../../subscription/data/enum/subscription-status.enum';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  constructor(private authService: AuthService) {}

  isRoleAllowed(roles: RoleEnum[]): boolean {
    const user = this.authService.userData();

    return roles.filter(value => user.roles.includes(value)).length > 0;
  }

  hasApiAccess() {
    return this.authService.userData().options?.apiAccess?.status;
  }

  isAuth(): boolean {
    return this.authService.userData().accountId != '';
  }

  isEmbeddedAuth(): boolean {
    return this.authService.userData().authMode === AuthModeEnum.embedded;
  }

  isStandardAuth(): boolean {
    return this.authService.userData().authMode === AuthModeEnum.standard;
  }

  isSubscribed(): boolean {
    if (
      this.isRoleAllowed([
        RoleEnum.ROLE_SUPER_ADMIN,
        RoleEnum.ROLE_ADMIN,
        RoleEnum.ROLE_CONTENT_MANAGER,
        RoleEnum.ROLE_PARTNER
      ])
    )
      return true;

    if (this.authService.userData().authMode === AuthModeEnum.embedded)
      return true;

    const subscription = this.authService.userData().subscription || undefined;

    return (
      subscription?.subscriptionStatus === SubscriptionStatusEnum.ACTIVE &&
      subscription?.priceId !== null
    );
  }

  isSubscriptionSuspended(): boolean {
    if (
      this.isRoleAllowed([
        RoleEnum.ROLE_SUPER_ADMIN,
        RoleEnum.ROLE_ADMIN,
        RoleEnum.ROLE_CONTENT_MANAGER,
        RoleEnum.ROLE_PARTNER
      ])
    )
      return false;

    if (this.authService.userData().authMode === AuthModeEnum.embedded)
      return false;

    const subscription = this.authService.userData().subscription || undefined;

    return (
      subscription?.subscriptionStatus === SubscriptionStatusEnum.INACTIVE &&
      subscription?.priceId !== null
    );
  }

  isUserPro() {
    const subscription = this.authService.userData().subscription || undefined;

    return (
      subscription?.priceId &&
      subscription?.subscriptionStatus === SubscriptionStatusEnum.ACTIVE
    );
  }

  isUserCustomer() {
    if (
      this.isRoleAllowed([
        RoleEnum.ROLE_SUPER_ADMIN,
        RoleEnum.ROLE_ADMIN,
        RoleEnum.ROLE_CONTENT_MANAGER,
        RoleEnum.ROLE_PARTNER
      ])
    )
      return false;

    if (this.authService.userData().authMode === AuthModeEnum.embedded)
      return true;

    const subscription = this.authService.userData().subscription || undefined;

    return subscription?.priceId;
  }

  hasSubscription() {
    const subscription = this.authService.userData().subscription || undefined;

    return subscription?.subscriptionId;
  }

  isCompactMode(): boolean {
    return this.authService.userData().embeddedOptions?.compactMode || false;
  }
}
