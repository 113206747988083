<section class="text-xs">
  <div
    *ngIf="authorizationService.isEmbeddedAuth()"
    class="bg-logo_black dark:bg-logo_white bg-no-repeat w-[130px] h-[30px]"
  ></div>
  <div class="filter-nav">
    <div class="views">
      <div class="flex ">
        <button
          (click)="setActiveFilterSection(0)"
          class="relative {{ activeFilterSection === 0 ? 'active' : '' }}"
        >
          Target
        </button>
        <button
          (click)="setActiveFilterSection(1)"
          class="relative {{ activeFilterSection === 1 ? 'active' : '' }}"
        >
          Buyer
        </button>
        <button
          (click)="setActiveFilterSection(2)"
          class="relative {{ activeFilterSection === 2 ? 'active' : '' }}"
        >
          Deal Info
        </button>
      </div>
    </div>
  </div>
  <div class="pt-5 pl-5 pr-5 relative">
    <mul-deal-filter-clear></mul-deal-filter-clear>
    <div *ngIf="activeFilterSection === 0">
      <mul-target-filter
        (dataMutationEventEmitter)="setTargetFilter($event)"
        [filterSelection]="targetFilterSelection"
        [geographyDataSet]="geographyDataSet"
        [targetOrganizationDataSet]="targetOrganizationsDataSet"
        [targetThemesDataSet]="industryThemesDataSet"
        [verticalDataSet]="verticalDataSet"
      ></mul-target-filter>
    </div>
    <div *ngIf="activeFilterSection === 1">
      <mul-buyer-filter
        (dataMutationEventEmitter)="setBuyerFilter($event)"
        [buyerOrganizationsDataSet]="buyerOrganizationsDataSet"
        [filterSelection]="buyerFilterSelection"
        [geographyDataSet]="geographyDataSet"
        [sponsorDataSet]="sponsorDataSet"
        [verticalDataSet]="verticalDataSet"
      ></mul-buyer-filter>
    </div>
    <div *ngIf="activeFilterSection === 2">
      <mul-deal-info-filter
        (dataMutationEventEmitter)="setDealInfoFilter($event)"
        [filterSelection]="dealInfoFilterSelection"
      ></mul-deal-info-filter>
    </div>
  </div>
  <div class="pl-5 pr-5 relative filter">
    <div (click)="triggerSearch()" class="filter-action">
      <button type="button">
        <i class="mr-1 fa-solid fa-filter"></i>Filter Data
      </button>
    </div>
  </div>
  <mul-footer
    *ngIf="authorizationService.isEmbeddedAuth()"
    class="flex mt-3"
  ></mul-footer>
</section>
