import { Component, Input } from '@angular/core';

@Component({
  selector: 'mul-api-token-display',
  templateUrl: './api-token-display.component.html'
})
export class ApiTokenDisplayComponent {
  @Input() token: string | null = null;
  @Input() codeText: string | null = null;

  copyTokenToClipboard() {
    if (this.token) {
      navigator.clipboard.writeText(this.token).finally();
    }
  }

  copyCodeToClipboard() {
    if (this.codeText) {
      navigator.clipboard.writeText(this.codeText).finally();
    }
  }
}
