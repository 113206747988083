import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PersonalAccessTokenResponseType } from '../data/type/personal-access-token-response.type';
import { DateTime } from 'luxon';
import { ApiTokenService } from '../services/api-token.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'mul-api-token-list',
  templateUrl: './api-token-list.component.html'
})
export class ApiTokenListComponent {
  @Output() deleteEventEmitter: EventEmitter<void> = new EventEmitter();
  @Output() renewEventEmitter: EventEmitter<string> = new EventEmitter();

  @Input() userTokens: PersonalAccessTokenResponseType[] = [];

  protected readonly DateTime = DateTime;

  constructor(
    private readonly apiTokenService: ApiTokenService,
    private spinner: NgxSpinnerService
  ) {}

  regenerateToken(id: string) {
    this.spinner.show('token');
    this.apiTokenService.regenerate$(id).subscribe(res => {
      this.renewEventEmitter.emit(res.token);
    });
  }

  deleteToken(id: string) {
    this.spinner.show('token');
    this.apiTokenService.delete$(id).subscribe(() => {
      this.deleteEventEmitter.emit();
    });
  }
}
