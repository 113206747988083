import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { SubscriptionService } from '../../services/subscription.service';
import { AuthService } from '../../../auth/services/auth.service';
import { SnackBarService } from '../../../uicommon/service/snack-bar.service';
import { environment } from '../../../../environments/environment';
import { SnackMessageStatusEnum } from '../../../uicommon/data/enum/snackMessageStatus.enum';

@Component({
  selector: 'app-subscription-checkout-action',
  templateUrl: './subscription-checkout-action.component.html'
})
export class SubscriptionCheckoutActionComponent {
  public env = environment;
  showErrorBox = false;

  constructor(
    private subscriptionService: SubscriptionService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBarService: SnackBarService,
    private spinner: NgxSpinnerService
  ) {
    this.spinner?.show('global');

    this.route.params.subscribe(params => {
      const accountId = params['accountId'];
      const sessionId = params['sessionId'];

      this.subscriptionService
        .updateAccountSubscription({ accountId, sessionId })
        .subscribe({
          next: () => {
            this.authService.subscriptionLogIn(sessionId).subscribe({
              complete: () => this.router.navigate(['/data-sheet']),
              error: () => {
                this.showErrorBox = true;
                this.spinner?.hide('global');
                this.snackBarService.snack({
                  type: SnackMessageStatusEnum.ERROR,
                  message: 'Subscription authorization failure'
                });
              }
            });
          }
        });
    });
  }

  returnToPlatform() {
    this.spinner?.show('global');
    this.router.navigate(['/data-sheet']);
  }
}
