import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { UIOptionType } from '../../../data-sheet/data/type/filter/UIOption.type';

@Component({
  selector: 'mul-keyword-balloon',
  templateUrl: './keyword.component.html'
})
export class KeywordComponent implements OnInit {
  @ViewChild('keywordInputElement') keywordInputElement!: ElementRef;

  @Output() dataMutationEventEmitter: EventEmitter<
    string[]
  > = new EventEmitter();

  @Input() selection: string[] = [];
  @Input() label: string | undefined = undefined;
  @Input() placeholder: string = '';
  @Input() componentId: string = '';

  selectionObjectGroup: UIOptionType[] = [];

  ngOnInit(): void {
    this.selectionObjectGroup = this._toUIOptionType(this.selection);
  }

  onFormKeywordsItemChange() {
    const items = this.keywordInputElement.nativeElement.value
      .split(',')
      .map((it: string) => it.trim());

    this.selection = [...this.selection, ...items].sort();
    this.selectionObjectGroup = this._toUIOptionType(this.selection);

    this.keywordInputElement.nativeElement.value = '';

    this.dataMutationEventEmitter.emit(this.selection);
  }

  removeItem($event: string | number) {
    const itemIndex = this.selection.findIndex(it => it == $event);

    if (itemIndex > -1) {
      this.selection.splice(itemIndex, 1);
      this.selectionObjectGroup = this._toUIOptionType(this.selection);

      this.dataMutationEventEmitter.emit(this.selection);
    }
  }

  private _toUIOptionType(items: string[]) {
    return items.map(it => ({ label: it, index: it, ref: it }));
  }
}
