<code
  class="text-xxs inline-flex text-left items-center space-x-4 bg-primary-800 text-primary-300 rounded-md p-2 pl-6"
>
  <span class="flex gap-4">
    <span [hidden]="!console" class="shrink-0 text-gray-500">
      $
    </span>
    <span class="flex-1">
      <span>
        {{ codeText }}
      </span>
    </span>
  </span>

  <svg
    (click)="copyEventEmitter.emit()"
    aria-hidden="true"
    class="shrink-0 h-5 w-5 transition text-primary-400 hover:text-primary-300  group-hover:text-white cursor-pointer group-focus:text-primary-0"
    fill="currentColor"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8 2a1 1 0 000 2h2a1 1 0 100-2H8z"></path>
    <path
      d="M3 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v6h-4.586l1.293-1.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L10.414 13H15v3a2 2 0 01-2 2H5a2 2 0 01-2-2V5zM15 11h2a1 1 0 110 2h-2v-2z"
    ></path>
  </svg>
</code>
