import { Injectable } from '@angular/core';
import { ApiService } from '../../common/service/api.service';
import { ApiRoutes } from '../../common/data/enum/routes.enum';
import { Observable } from 'rxjs';
import { PersonalAccessTokenType } from '../data/type/personal-access-token.type';
import { PersonalAccessTokenResponseType } from '../data/type/personal-access-token-response.type';
import { PersonalAccessTokenCreateResponseType } from '../data/type/personal-access-token-create-response.type';

@Injectable({
  providedIn: 'root'
})
export class ApiTokenService {
  constructor(private api: ApiService) {}

  getAll$(): Observable<PersonalAccessTokenResponseType[]> {
    return this.api.httpGet$(ApiRoutes.PERSONAL_ACCESS_TOKEN_INDEX);
  }

  create$(
    data: PersonalAccessTokenType
  ): Observable<PersonalAccessTokenCreateResponseType> {
    return this.api.httpPost$(ApiRoutes.PERSONAL_ACCESS_TOKEN, data);
  }

  delete$(id: string) {
    return this.api.httpDelete$(`${ApiRoutes.PERSONAL_ACCESS_TOKEN}${id}`);
  }

  regenerate$(id: string): Observable<PersonalAccessTokenCreateResponseType> {
    return this.api.httpPut$(
      `${ApiRoutes.PERSONAL_ACCESS_TOKEN_REGENERATE}${id}`
    );
  }
}
