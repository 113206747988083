<main class="h-screen flex flex-col items-center justify-center tracking-tight">
  <div [style]="{ width: '22rem' }" class="auth mb-5">
    <h1 class="flex flex-col items-center mb-5">
      <a href="{{ env.site_url }}"></a>
    </h1>
    <div
      *ngIf="showErrorBox"
      class="flex flex-col items-center justify-center flex-1"
    >
      <p>
        A problem has occurred while processing your payment.<br />
        Please
        <mul-upgrade-action label="click here"></mul-upgrade-action>
        to try again or <a (click)="returnToPlatform()">return</a> to the
        platform.
      </p>
    </div>
    <div
      *ngIf="!showErrorBox"
      class="flex flex-col items-center justify-center flex-1"
    >
      <p>
        Thank you. Your payment has been processed.
      </p>
    </div>
  </div>
  <mul-footer></mul-footer>
</main>
