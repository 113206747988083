<form [formGroup]="tokenForm">
  <div class="flex flex-col bg-primary-100 dark:bg-inverse-100 p-3 rounded-sm">
    <div class="flex flex-col mb-2">
      <label class="block mb-1 text-sm text-gray-900 dark:text-white">
        Provide token descriptive name
      </label>
      <input
        class="block w-full bg-primary-50 dark:bg-inverse-50 border-primary-300 dark:border-inverse-300 text-primary-900 dark:text-inverse-900 text-xs rounded p-2"
        id="name"
        placeholder="API secret token key for UK-1 cluster"
        type="text"
        formControlName="name"
      />
    </div>
    <div class="flex flex-col">
      <label class="block mb-1 text-sm text-gray-900 dark:text-white">
        Provide token lifetime
      </label>
      <select
        class="block w-full bg-primary-50 dark:bg-inverse-50 border-primary-300 dark:border-inverse-300 text-primary-900 dark:text-inverse-900 text-xs rounded p-2"
        id="publicDataFields"
        formControlName="expirationHours"
      >
        <option [selected]="true" value="1M">One month</option>
        <option value="3M">Three months</option>
        <option value="1Y">One year</option>
        <option value="F">Forever</option>
      </select>
    </div>
    <div class="flex justify-end text-primary-600 dark:text-inverse-600">
      <button
        (click)="cancelAction()"
        class="text-xs flex flex-row justify-center align-baseline items-baseline hover:bg-primary-200 dark:hover:bg-inverse-200 rounded-sm p-2 my-1 mr-1"
        type="button"
      >
        <i class="fa-solid fa-xmark mr-1"></i>
        <span>Cancel</span>
      </button>
      <button
        (click)="saveAction()"
        class="text-xs flex flex-row justify-center align-baseline items-baseline hover:bg-primary-200 dark:hover:bg-inverse-200 rounded-sm p-2 my-1 mr-0"
        type="button"
      >
        <i class="fa-solid fa-floppy-disk mr-1"></i>
        <span>Create</span>
      </button>
    </div>
  </div>
</form>
