import { Component } from '@angular/core';
import { ApiTokenDialogService } from '../services/api-token-dialog.service';
import { ApiTokenService } from '../services/api-token.service';
import { PersonalAccessTokenResponseType } from '../data/type/personal-access-token-response.type';
import { environment } from '../../../environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'mul-app-api-token',
  templateUrl: './api-token.component.html'
})
export class ApiTokenComponent {
  readonly dialogStatus = this.apiTokenDialogService.dialogStatus;

  userTokens: PersonalAccessTokenResponseType[] = [];
  displayForm: boolean = false;
  currentToken: string | null = null;
  codeText: string | null = null;

  constructor(
    private readonly apiTokenDialogService: ApiTokenDialogService,
    private readonly apiTokenService: ApiTokenService,
    private spinner: NgxSpinnerService
  ) {
    this._fetchData();
  }

  dismiss() {
    this.displayForm = false;
    this.currentToken = null;
    this.spinner.hide('token');
    this.apiTokenDialogService.hide();
  }

  displayTokenForm() {
    this.displayForm = true;
    this.currentToken = null;
  }

  listDeleteEventEmitterAction() {
    this.displayForm = false;
    this.currentToken = null;
    this._fetchData();
  }

  saveEventEmitterAction($event: string | null) {
    this.currentToken = $event;
    this.codeText = `curl "${environment.app_url}${environment.api_prefix}/data-grid?page=1&pageSize=100&skus=NAS_AAPL,NAS_NVDA" -H "Authorization: Bearer ${$event}"`;
    this.displayForm = false;
    this._fetchData();
  }

  listRenewEventEmitterAction($event: string | null) {
    this.currentToken = $event;
    this.codeText = `curl "${environment.app_url}${environment.api_prefix}/data-grid?page=1&pageSize=100&skus=NAS_AAPL,NAS_NVDA" -H "Authorization: Bearer ${$event}"`;
    this.displayForm = false;
    this._fetchData();
  }

  private _fetchData() {
    this.apiTokenService.getAll$().subscribe(res => {
      this.userTokens = res;
      this.spinner.hide('token');
    });
  }
}
