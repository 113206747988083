import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuardService } from '../common/service/guards/auth-guard.service';
import { ApiTokenComponent } from './api-token/api-token.component';

const configurationRoutes: Routes = [
  {
    path: 'configuration',
    children: [
      {
        path: 'personal-access-token',
        component: ApiTokenComponent,
        canActivate: [authGuardService]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(configurationRoutes)],
  exports: [RouterModule]
})
export class ConfigurationRoutingModule {}
