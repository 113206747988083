import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApiTokenDialogService {
  private readonly dialogState = signal(false);

  readonly dialogStatus = this.dialogState.asReadonly();

  show() {
    this.dialogState.set(true);
  }

  hide() {
    this.dialogState.set(false);
  }
}
