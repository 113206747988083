import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { SnackBarService } from './service/snack-bar.service';
import { DialogComponent } from './components/dialog/dialog.component';
import { DialogService } from './service/dialog.service';
import { AlertComponent } from './components/icons/alert/alert.component';
import { SuccessComponent } from './components/icons/success/success.component';
import { InfoComponent } from './components/icons/info/info.component';
import { ErrorComponent } from './components/icons/error/error.component';
import { UiObserverStore } from './service/ui-observer.store';
import { MultiSelectComponent } from './components/multi-select/multi-select.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SwitchComponent } from './components/switch/switch.component';
import { KeywordComponent } from './components/keyword/keyword.component';
import { BalloonSetComponent } from './components/balloon-set/balloon-set.component';
import { CodeComponent } from '../ui/components/code/code.component';

@NgModule({
  declarations: [
    SnackbarComponent,
    DialogComponent,
    MultiSelectComponent,
    SwitchComponent,
    KeywordComponent,
    BalloonSetComponent,
    CodeComponent
  ],
  providers: [SnackBarService, DialogService, UiObserverStore],
  imports: [
    CommonModule,
    AlertComponent,
    SuccessComponent,
    InfoComponent,
    ErrorComponent,
    ReactiveFormsModule
  ],
  exports: [
    SnackbarComponent,
    DialogComponent,
    MultiSelectComponent,
    SwitchComponent,
    KeywordComponent,
    BalloonSetComponent,
    CodeComponent
  ]
})
export class UICommonModule {}
