import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  NgModule
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AuthInterceptorService } from './common/service/interceptors/auth-interceptor.service';
import { DataSheetModule } from './data-sheet/data-sheet.module';
import { GlobalHttpInterceptorService } from './common/service/interceptors/global-http-interceptor-service';
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule
} from 'ngx-google-analytics';
import { environment } from '../environments/environment';
import { AuthDomainInitializerService } from './common/service/initializer/auth-domain-initializer.service';
import * as Sentry from '@sentry/angular-ivy';
import { NgOptimizedImage } from '@angular/common';
import { UICommonModule } from './uicommon/uicommon.module';
import { AuthModule } from './auth/auth.module';
import { DARK_MODE_OPTIONS } from 'angular-dark-mode';
import { SpinnerComponent } from './uicommon/components/spinner/spinner.component';
import { ConfigurationModule } from './configuration/configuration.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    NgxGoogleAnalyticsModule.forRoot(environment.googleAnalyticsTrackingCode),
    NgxGoogleAnalyticsRouterModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxSpinnerModule.forRoot({ type: 'ball-clip-rotate' }),
    DataSheetModule,
    ConfigurationModule,

    // SocketIoModule.forRoot({
    //   url: environment.app_url,
    //   options: { closeOnBeforeunload: true, path: '/socket/' }
    // }),
    NgOptimizedImage,
    UICommonModule,
    AuthModule,
    SpinnerComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (service: AuthDomainInitializerService) => () => {
        service.embeddedDomainAuth();
      },
      deps: [AuthDomainInitializerService, Sentry.TraceService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GlobalHttpInterceptorService,
      multi: true
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({ showDialog: false })
    },
    {
      provide: DARK_MODE_OPTIONS,
      useValue: {
        darkModeClass: 'dark',
        lightModeClass: 'light'
      }
    }
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
