<div
  class="{{ dialogStatus() ? 'visible' : 'invisible' }} relative z-10 config"
>
  <div
    class="fixed inset-0 bg-primary-500 bg-opacity-75 transition-opacity"
  ></div>

  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div
      class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
    >
      <div class="relative transform overflow-hidden rounded bg-white">
        <div
          class="flex flex-row bg-primary-200 dark:bg-inverse-200 justify-between align-baseline items-baseline w-[600px] p-2"
        >
          <h2>Personal Access Tokens</h2>
          <button
            (click)="dismiss()"
            class="flex flex-row justify-center align-baseline text-primary-600 dark:text-inverse-600 hover:bg-primary-200 dark:hover:bg-inverse-200 rounded-sm p-1"
            type="button"
          >
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>
        <div
          class="bg-primary-50 dark:bg-inverse-50 px-4 pb-4 pt-1 p-6 sm:pb-4"
        >
          <div class="text-left">
            <div class="flex flex-col">
              <div class="flex justify-end">
                <ngx-spinner name="token" size="medium"></ngx-spinner>
                <button
                  (click)="displayTokenForm()"
                  class="flex flex-row justify-center align-baseline text-primary-600 dark:text-inverse-600 hover:bg-primary-200 dark:hover:bg-inverse-200 rounded-sm p-2 my-1 ml-3"
                  type="button"
                >
                  <i class="fa-solid fa-circle-plus mr-1"></i>
                  <span class="text-xs">Create new token</span>
                </button>
              </div>
              <mul-api-token-form
                (saveEventEmitter)="saveEventEmitterAction($event)"
                [hidden]="!displayForm"
              ></mul-api-token-form>

              <mul-api-token-display
                [codeText]="codeText"
                [hidden]="!currentToken"
                [token]="currentToken"
              ></mul-api-token-display>

              <mul-api-token-list
                (deleteEventEmitter)="listDeleteEventEmitterAction()"
                (renewEventEmitter)="listRenewEventEmitterAction($event)"
                [userTokens]="userTokens"
              ></mul-api-token-list>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
