<div class="balloon-box flex flex-col">
  <div *ngIf="selection.length > 0" class="flex flex-wrap">
    <div
      *ngFor="let item of selection; let i = index"
      [hidden]="i >= displayCount && !displayAllSelected"
    >
      <span class="pill">
        {{ item.label }}
        <button (click)="removeItem(item.index)" type="button">
          <i class="fa-solid fa-xmark"></i>
        </button>
      </span>
    </div>
  </div>

  <div class="flex justify-end">
    <span
      (click)="displayAllSelected = !displayAllSelected"
      *ngIf="selection.length > displayCount"
      class="extra"
    >
      <div [hidden]="displayAllSelected">
        +{{ selection.length - displayCount }}
      </div>
      <div [hidden]="!displayAllSelected">
        -{{ selection.length - displayCount }}
      </div>
    </span>
  </div>
</div>
