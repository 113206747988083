<div class="flex flex-col mt-3 h-[300px] overflow-auto">
  <ul *ngIf="userTokens.length">
    <li
      *ngFor="let token of userTokens; let i = index"
      class="flex justify-between mb-1 border-b-2 py-1"
    >
      <div class="block-1 flex flex-col mr-3">
        <div>
          <span class="text-sm text-primary-700 dark:text-inverse-700">
            {{ token.name }}
          </span>
        </div>
        <span class="text-xs text-primary-600 dark:text-inverse-600">
          Expires on
          {{ DateTime.fromMillis(token.expirationTime).toHTTP() }}
        </span>
      </div>
      <div class="block-2 flex flex-row text-primary-600 dark:text-inverse-600">
        <button
          (click)="regenerateToken(token.id)"
          class="text-xs flex flex-row justify-center align-baseline items-baseline hover:bg-primary-200 dark:hover:bg-inverse-200 rounded-sm p-2 my-1 mr-1"
          type="button"
        >
          <i class="fa-solid fa-rotate mr-1"></i>
          <span>Renew</span>
        </button>
        <button
          (click)="deleteToken(token.id)"
          class="text-xs flex flex-row justify-center align-baseline items-baseline hover:bg-primary-200 dark:hover:bg-inverse-200 rounded-sm p-2 my-1 mr-0"
          type="button"
        >
          <i class="fa-solid fa-trash-can mr-1"></i>
          <span>Delete</span>
        </button>
      </div>
    </li>
  </ul>
  <div *ngIf="!userTokens.length" class="flex justify-center">
    <span class="text-xs text-primary-500 dark:text-inverse-500"
      >There are no personal access tokens available.</span
    >
  </div>
</div>
