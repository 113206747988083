import { Injectable } from '@angular/core';
import { PublicDataFilter } from '../../../data/type/filter/public/public-data-filter.type';
import { ApiRoutes } from '../../../../common/data/enum/routes.enum';
import { PublicDataGridFilterService } from '../../public-data-grid-filter.service';
import { ApiService } from '../../../../common/service/api.service';
import { GridMutatorService } from '../../mutator/grid-mutator.service';
import { Observable } from 'rxjs';
import { DataSortingService } from '../../data-sorting.service';
import { DataSheetStore } from '../../store/data-sheet.store';
import { FilterRefEnum } from '../../../data/enum/grid/filter-ref.enum';

@Injectable({
  providedIn: 'root'
})
export class PublicDataExportService {
  constructor(
    private gridFilterService: PublicDataGridFilterService,
    private gridMutatorService: GridMutatorService,
    private dataSortingService: DataSortingService,
    public dataSheetStorageService: DataSheetStore,
    private api: ApiService
  ) {}

  export$(viewsIds: string): Observable<Blob> {
    const sorting = this.dataSortingService.gridSortStore.sort;
    if (!sorting.column)
      sorting.column = this.dataSheetStorageService.fieldMap.get(
        FilterRefEnum.EV
      )!;

    const filter: PublicDataFilter = this.gridFilterService.gridFilterStore
      .gridFilter;

    const mutation = this.gridMutatorService.gridMutatorStorage.gridMutation;

    const url = ApiRoutes.DATA_GRID_EXPORT.valueOf();

    return this.api.httpPost$<Blob>(
      url,
      {
        views: viewsIds.split(','),
        filter: filter,
        mutation: mutation,
        sorting: sorting
      },
      { responseType: 'blob' as 'json' }
    );
  }
}
