<div
  (click)="dismiss()"
  class="{{ promoType ? 'visible' : 'invisible' }} relative z-10 promo"
>
  <div
    (click)="dismiss()"
    class="fixed inset-0 bg-primary-500 bg-opacity-75 transition-opacity"
  ></div>

  <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
    <div
      class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
    >
      <div
        class="relative transform overflow-hidden rounded bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl"
      >
        <div
          class="bg-primary-50 dark:bg-inverse-50 px-4 pb-4 pt-5 sm:p-6 sm:pb-4"
        >
          <div class="sm:flex sm:items-start">
            <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
              <div class="flex flex-col items-center justify-center mt-2">
                <h2 class="text-2xl font-bold mb-1">
                  {{ selectPromo.title }}
                </h2>
                <p>
                  {{ selectPromo.subtitle }}
                </p>
                <img
                  *ngIf="selectPromo.image"
                  alt="Filter through 160+ verticals like B2B marketplaces, defense tech, IoT and
    mode"
                  class="my-5"
                  height="465"
                  ngSrc="{{ selectPromo.image }}"
                  width="800"
                />
                <mul-upgrade-action
                  label="Upgrade your plan"
                ></mul-upgrade-action>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
