import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthorizationService } from '../../../common/service/authorization.service';
import { PublicDataGridFilterService } from '../../service/public-data-grid-filter.service';
import { UIOptionType } from '../../data/type/filter/UIOption.type';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mul-public-data-filter',
  templateUrl: './public-data-filter.component.html'
})
export class PublicDataFilterComponent implements OnInit, OnDestroy {
  private verticalSubscription: Subscription = new Subscription();
  private industryThemesSubscription: Subscription = new Subscription();
  private geographySubscription: Subscription = new Subscription();
  private organizationsSubscription: Subscription = new Subscription();
  private gridFilterSubscription: Subscription = new Subscription();

  geographyDataSet: UIOptionType[] = [];
  geographySelection: UIOptionType[] = [];

  verticalDataSet: UIOptionType[] = [];
  verticalSelection: UIOptionType[] = [];

  industryThemesDataSet: UIOptionType[] = [];
  industryThemesSelection: UIOptionType[] = [];

  organizationDataSet: UIOptionType[] = [];
  organizationSelection: UIOptionType[] = [];

  organizationRowsSelection: UIOptionType[] = [];

  constructor(
    protected readonly authorizationService: AuthorizationService,
    private readonly gridFilterService: PublicDataGridFilterService
  ) {
    this.gridFilterService.gridFilterStore.filterCount$.subscribe(val => {
      if (val === 0) {
        this.geographySelection = [];
        this.verticalSelection = [];
        this.organizationSelection = [];
        this.organizationRowsSelection = [];
        this.industryThemesSelection = [];
      }
    });
  }

  ngOnInit(): void {
    this.verticalSubscription = this.gridFilterService.filterStorageService.verticals$.subscribe(
      val => {
        this.verticalDataSet = val;
      }
    );

    this.industryThemesSubscription = this.gridFilterService.filterStorageService.industryThemes$.subscribe(
      val => {
        this.industryThemesDataSet = val;
      }
    );

    this.geographySubscription = this.gridFilterService.filterStorageService.geographies$.subscribe(
      val => {
        this.geographyDataSet = val;
      }
    );

    this.organizationsSubscription = this.gridFilterService.filterStorageService.organizations$.subscribe(
      val => {
        this.organizationDataSet = val;
      }
    );

    this.gridFilterSubscription = this.gridFilterService.gridFilterStore.gridFilter$.subscribe(
      val => {
        this.geographySelection = this.geographyDataSet?.filter(it =>
          val.geographies.includes(it.ref)
        );

        this.verticalSelection = this.verticalDataSet?.filter(it =>
          val.tags.includes(it.ref)
        );

        this.industryThemesSelection = this.industryThemesDataSet?.filter(it =>
          val.industryThemes.includes(it.ref)
        );

        this.organizationSelection = this.organizationDataSet?.filter(it =>
          val.organizations.includes(it.ref)
        );

        this.organizationRowsSelection = this.organizationDataSet?.filter(it =>
          val.organizationRows.includes(it.ref)
        );
      }
    );
  }

  ngOnDestroy(): void {
    this.verticalSubscription.unsubscribe();
    this.geographySubscription.unsubscribe();
    this.organizationsSubscription.unsubscribe();
    this.gridFilterSubscription.unsubscribe();
    this.industryThemesSubscription.unsubscribe();
  }

  setGeography($event: string[]) {
    this.gridFilterService.gridFilterStore.geographies = $event;
  }

  setThemes($event: string[]) {
    this.gridFilterService.gridFilterStore.industryThemes = $event;
  }

  setVerticals($event: string[]) {
    this.gridFilterService.gridFilterStore.verticals = $event;
  }

  setOrganizations($event: string[]) {
    this.gridFilterService.gridFilterStore.organizations = $event;
  }

  setOrganizationRows($event: string[]) {
    this.gridFilterService.gridFilterStore.organizationRows = $event;
  }
}
