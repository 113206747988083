<div (keydown.escape)="exit()" class="dropdown-search flex flex-col">
  <div class="flex flex-col items-center justify-center">
    <h2 class="flex items-center">
      {{ label }}
      <!--      <i *ngIf="tooltip" class="fa-regular fa-circle-question ml-1 peer"> </i>-->
      <!--      <div class="tooltip-container peer-hover:visible peer-hover:opacity-100">-->
      <!--        <div *ngIf="tooltip" class="tooltip">-->
      <!--          <i class="fa-solid fa-caret-left"></i>-->
      <!--          {{ tooltip }}-->
      <!--        </div>-->
      <!--      </div>-->
    </h2>
    <form [formGroup]="componentForm">
      <i class="fa-solid fa-magnifying-glass"></i>
      <input
        (click)="open()"
        autocomplete="off"
        formControlName="formField"
        id="formField"
        placeholder="{{ placeholder }}"
        type="text"
      />
      <i (click)="exit()" *ngIf="componentState" class="fa-solid fa-xmark"> </i>
    </form>

    <div #uiContainer (mouseleave)="exit()" class="relative">
      <div class="dropdown-layer {{ !componentState ? 'hidden' : '' }}">
        <div
          *ngIf="data?.length === 0"
          class="flex justify-center items-center"
        >
          <span>No matching results</span>
        </div>

        <div *ngIf="multiLevel">
          <div *ngFor="let item of data; let i = index" class="mb-2">
            <span (click)="addItem(item.index)">
              {{ item.label }}
            </span>
            <div
              *ngFor="let child of item.children"
              class="flex items-center mb-1"
            >
              <input
                (click)="addItem(child.index)"
                [checked]="isSelected(child.index)"
                id="{{ child.index }}"
                type="checkbox"
                value="{{ child.index }}"
              />

              <label for="{{ child.index }}">
                {{ child.label }}
              </label>
            </div>
          </div>
        </div>

        <div *ngIf="!multiLevel">
          <div
            *ngFor="let item of data; let i = index"
            class="flex align-middle mb-1"
          >
            <input
              (click)="addItem(item.index)"
              [checked]="isSelected(item.index)"
              id="{{ item.index }}"
              type="checkbox"
              value="{{ item.index }}"
            />

            <label for="{{ item.index }}">
              {{ item.label }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="selection.length > 0" class="flex flex-wrap">
    <div
      *ngFor="let item of selection; let i = index"
      [hidden]="i >= displayCount && !displayAllSelected"
    >
      <span class="pill">
        {{ item.label }}
        <button (click)="removeItem(item.index)" type="button">
          <i class="fa-solid fa-xmark"></i>
        </button>
      </span>
    </div>
  </div>

  <div class="flex justify-end">
    <span
      (click)="displayAllSelected = !displayAllSelected"
      *ngIf="selection.length > displayCount"
      class="extra"
    >
      <div [hidden]="displayAllSelected">
        +{{ selection.length - displayCount }}
      </div>
      <div [hidden]="!displayAllSelected">
        -{{ selection.length - displayCount }}
      </div>
    </span>
  </div>
</div>
