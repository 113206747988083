import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ApiTokenComponent } from './api-token/api-token.component';
import { ConfigurationRoutingModule } from './configuration-routing-module';
import { DataSheetModule } from '../data-sheet/data-sheet.module';
import { NavigationModule } from '../navigation/navigation.module';
import { SubscriptionModule } from '../subscription/subscription.module';
import { ApiTokenListComponent } from './api-token-list/api-token-list.component';
import { ApiTokenFormComponent } from './api-token-form/api-token-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ApiTokenDisplayComponent } from './api-token-display/api-token-display.component';
import { UICommonModule } from '../uicommon/uicommon.module';
import { SpinnerComponent } from '../uicommon/components/spinner/spinner.component';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  declarations: [
    ApiTokenComponent,
    ApiTokenListComponent,
    ApiTokenFormComponent,
    ApiTokenDisplayComponent
  ],
  exports: [ApiTokenComponent],
  imports: [
    CommonModule,
    ConfigurationRoutingModule,
    DataSheetModule,
    NavigationModule,
    NgOptimizedImage,
    SubscriptionModule,
    FormsModule,
    ReactiveFormsModule,
    UICommonModule,
    SpinnerComponent,
    NgxSpinnerModule
  ]
})
export class ConfigurationModule {}
